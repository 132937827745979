import React, {useState} from 'react';
import {images} from '../../constants';
import './Navbar.scss';



const Navbar = () => {
    return (
        <nav className="app__navbar">

            <div className="app__navbar-logo">
                <img src={images.logo} alt="logo" />    
            </div>
           <div className="app__navbar-links">
           <li>
           <a href="https://ordunodev21.com/">Home</a>
           </li>
           </div>
        </nav>
    )
}

export default Navbar
