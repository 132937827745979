import React, {useState,useEffect} from 'react';
import './Dex.scss';
import {getPokedexList,getPokemonSprite,getShinySprite} from'./helper.js';


const Dex = () => {
  
    const [pokemon, setPokemon] = useState([]);
    const [pokemonIndex, setPokemonIndex] = useState([]);
    const [showImage, setShowImage] = useState(false);
    const [type, setType] = useState("");
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [id, setId] = useState(1);
    const [ability, setAbility] = useState("");
    const [flavor, setFlavor] = useState(1)
    const [flavorDesc, setFlavorDesc] = useState("")

    useEffect(() => {
        async function getPokemonData() {
            const apiData = await getPokedexList();
            setPokemon(apiData);
            
        }
        getPokemonData();
    }, []);

    const pokemonNamesList = pokemon.map((item,index)=>
{
    return (<option key={index} value={index + 1}>{index+1}-{item.name.toUpperCase()}</option>)
})


 const handleClick = () => {
    setShowImage(current => !current);
  };

  useEffect(() => {
  }, [showImage]);


  useEffect(() => {
   async function getFlavorData() {
    const data = await fetch(`https://pokeapi.co/api/v2/pokemon-species/${flavor}`).then((res) => res.json())
    var lang = 'en'
    if (data) {
        for (const entry in data) {
            if (entry === 'flavor_text_entries') { 
                for (const flavor in data[`${entry}`]) {
                    
                    if (data[`${entry}`][`${flavor}`]['language'].name === `${lang}`) {
                        setFlavorDesc(data[`${entry}`][`${flavor}`].flavor_text)
                       break;
                    }
                }
            }
        }
    } else {
    // console.log('No desc!');

  }
   
    // setFlavorDesc(data.flavor_text_entries[1].flavor_text.toUpperCase())

   }
  
  getFlavorData();

  
}, [flavor]);



  useEffect(() => {
    async function getData() {
      const data = await fetch(`https://pokeapi.co/api/v2/pokemon/${id}`).then((res) => res.json())
    

      const typesDataInitial = []
      typesDataInitial.push(data.types[0].type.name)


    try {
        typesDataInitial.push(data.types[1].type.name)
    } catch (error) {
        // console.log("error")
        
    }

    // console.log(typesDataInitial)
      setType(typesDataInitial);
      setWeight(data.weight);
  
      setHeight(data.height);
      setAbility(data.abilities[0].ability.name)

    }
    getData();
   
  }, [id]);

    return (
        
        <div className="app__body">

       
  <div className="body-left">
 
  <p>Welcome to a simple Pokedex that displays every pokemon ever created from Generation 1 to Generation 9.
            <br/>
           The app is not affiliated with the pokemon company and is used for educational purposes.
           <br/>
           All images and data were used from <a href="https://pokeapi.co/">PokeAPI</a>
             </p>
  </div>

  <div className="body-right">
    <div className="app__dex">
    <p> Pokedex</p>
        <select onChange={(e)=> {setPokemonIndex(e.target.value); e.target.value > 1008 ? setId(Number(e.target.value) + 8992) : setId(e.target.value); setFlavor(e.target.value);}}>
           
             {pokemonNamesList}
        
        </select>

        

        <button onClick={handleClick}>{!showImage ? "Normal Pokemon " : "Shiny Pokemon"}</button>
         
        <div className="app__dex_Image">
        
    
        {!showImage ? <img src={pokemonIndex.length !== 0 ? getPokemonSprite(pokemonIndex) :  getPokemonSprite(1)}/>: <img src={pokemonIndex.length !== 0 || pokemonIndex > 1008 ? getShinySprite(pokemonIndex) : getShinySprite(1) } alt="Shiny Sprite does not exist"/>}
        </div>

        <div className="app__dex_desc">
        <p>Description:</p>
        <p>{flavorDesc}</p>
        <p>Type: {type[0]}  {type[1]} </p>
        <p>Height: {height} m</p>
        <p>Weight: {weight} kg</p>
        <p>Ability: {ability}</p>

 
        </div>
    </div>
  </div>


       


    
         

        
           
        </div>
        
       
      
    
        
    
            
     

        

        
 
    )
    

}

export default Dex
