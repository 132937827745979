import logo from './logo.svg';
import './App.scss';
import {Dex, Footer} from './container';
import {Navbar} from './components';


function App() {
  return (
    <div className="App">

      <Navbar />
      <Dex />
      <Footer />

    </div>
  );
}

export default App;
