import React, {useState} from 'react';
import './Footer.scss';


const Footer = () => {
    return (
        <div className="app__footer">
           <div className="copyright">
                    <p className="p-text">@2022 Angel</p>
                    <p className="p-text">All rights reserved</p>

                </div>
        </div>
    )
}

export default Footer
