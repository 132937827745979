
export async function getPokedexList() {
    const data = await fetch(`https://pokeapi.co/api/v2/pokemon?offset=0&limit=1278`).then((res) => res.json());
    return data.results;
}
export function getPokemonSprite(id) {
    if (id > 1008){
        var newSetIndex = 8992;
        id = +id + newSetIndex; 
        return (`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${id}.png`)

    }else
    return (`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${id}.png`) 
};
export function getShinySprite(id) {
    if (id > 1008){
        var newSetIndex = 8992;
        id = +id + newSetIndex; 
        return (`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/home/shiny/${id}.png`)
    }else
    return (`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/home/shiny/${id}.png`) ;

}